<template>
    <!-- Component: organisms/key-events-block -->
    <general-container :customClass="customClass" :title="'Key Events On The Session Calendar'">

        <preloader v-if="loading"></preloader>

        <section v-show="!loading" ref="timeline" id="timeline" class="cd-h-timeline margin-bottom-md">
            <div class="cd-h-timeline__container container">
                <div class="cd-h-timeline__dates">
                    <div class="cd-h-timeline__line">
                        <ol>
                            <template v-for="itemGroup in itemsGrouped">
                                <li><a :data-date="dateShort(itemGroup[0].dateFrom)" class="cd-h-timeline__date" :class="itemGroup[0].selected ? 'cd-h-timeline__date--selected' : ''">{{ prettyDateShort(itemGroup[0].dateFrom) }}</a></li>
                            </template>
                        </ol>

                        <span class="cd-h-timeline__filling-line" aria-hidden="true"></span>
                    </div> <!-- .cd-h-timeline__line -->
                </div> <!-- .cd-h-timeline__dates -->

                <ul>
                    <li><a class="text-replace cd-h-timeline__navigation cd-h-timeline__navigation--prev cd-h-timeline__navigation--inactive">Prev</a></li>
                    <li><a class="text-replace cd-h-timeline__navigation cd-h-timeline__navigation--next">Next</a></li>
                </ul>
            </div> <!-- .cd-h-timeline__container -->

            <div class="cd-h-timeline__events">
                <ol>
                    <template v-for="itemGroup in itemsGrouped">
                        <li :class="itemGroup[0].selected ? 'cd-h-timeline__event--selected' : ''" class="cd-h-timeline__event text-component">
                            <template v-for="item in itemGroup">
                                <div class="cd-h-timeline__event-content container">
                                <p class="cd-h-timeline__event-title h2">{{ item.name }}</p>
                                <p class="cd-h-timeline__event-date">{{ prettyDate(item.dateFrom) }}</p>
                                <p class="cd-h-timeline__event-description color-contrast-medium" v-html="item.description"></p>
                                </div>
                            </template>
                        </li>
                    </template>

                    <!-- other descriptions here -->
                </ol>
            </div> <!-- .cd-h-timeline__events -->
        </section>

        <read-more-link
            v-if="readMoreLink?.href" :url="readMoreLink.href" :contents="readMoreLink.name"
            target="_blank" :icon="'calendar'"
        />

    </general-container>
    <!-- End Component: organisms/key-events-block -->
</template>

<script>
    import GeneralContainer from "./general-container"
    import RequestHandler from "@/handler/RequestHandler"
    import Preloader from "./preloader";
    import ReadMoreLink from "./read-more-link"
    import "@/helpers/timeline";
    import { reactive } from "vue";

    export default {
        name: 'KeyEventsBlock',
        props: {
            name: String,
            readMoreLink: Object,
        },
        components: {
            GeneralContainer,
            Preloader,
            ReadMoreLink
        },
        mounted() {
            this.loading = true;
            RequestHandler.loadKeyEvents().then(response => {
                this.items = response.items;
                let today = new Date();
                today.setHours(0, 0, 0);
                let lastDateKey = '';
                let firstFutureDateKey = '';
                for (let i = 0; i < this.items.length; i++) {
                    let item = this.items[i];
                    let date = new Date(item.dateFrom.date);
                    if (date < today) {
                        item.selected = false;
                    }
                    let dateKey = this.formatDate(date, 'dd/MM/yyyy');
                    if (date >= today && !firstFutureDateKey) {
                        firstFutureDateKey = dateKey;
                    }
                    lastDateKey = dateKey;
                    if (!this.itemsGrouped.hasOwnProperty(dateKey)) {
                       this.itemsGrouped[dateKey] = [];
                    }
                    this.itemsGrouped[dateKey].push(item);
                }
                if (!firstFutureDateKey) {
                    this.itemsGrouped[lastDateKey][0].selected = true;
                }
                else {
                    this.itemsGrouped[firstFutureDateKey][0].selected = true;
                }
                this.loading = false;
                let _this = this;
                this.$nextTick(function() {
                    new HorizontalTimeline(_this.$refs.timeline);
                });
            }).catch((error) => {
                console.error(error);
            });
        },
        data() {
            return {
                customClass: "key-events has-text-centered js",

                items: [],
                itemsGrouped: {},
                loading: true,
            };
        },
        methods: {
            twoDigitPad(num) {
                return num < 10 ? "0" + num : num;
            },
            formatDate(date, patternStr){
                var monthNames = [
                    "January", "February", "March", "April", "May", "June", "July",
                    "August", "September", "October", "November", "December"
                ];
                var dayOfWeekNames = [
                    "Sunday", "Monday", "Tuesday",
                    "Wednesday", "Thursday", "Friday", "Saturday"
                ];
                if (!patternStr) {
                    patternStr = 'M/d/yyyy';
                }
                var day = date.getDate(),
                    month = date.getMonth(),
                    year = date.getFullYear(),
                    hour = date.getHours(),
                    minute = date.getMinutes(),
                    second = date.getSeconds(),
                    miliseconds = date.getMilliseconds(),
                    h = hour % 12,
                    hh = this.twoDigitPad(h),
                    HH = this.twoDigitPad(hour),
                    mm = this.twoDigitPad(minute),
                    ss = this.twoDigitPad(second),
                    aaa = hour < 12 ? 'AM' : 'PM',
                    EEEE = dayOfWeekNames[date.getDay()],
                    EEE = EEEE.substr(0, 3),
                    dd = this.twoDigitPad(day),
                    M = month + 1,
                    MM = this.twoDigitPad(M),
                    MMMM = monthNames[month],
                    MMM = MMMM.substr(0, 3),
                    yyyy = year + "",
                    yy = yyyy.substr(2, 2)
                ;
                // checks to see if month name will be used
                patternStr = patternStr
                .replace('hh', hh).replace('h', h)
                .replace('HH', HH).replace('H', hour)
                .replace('mm', mm).replace('m', minute)
                .replace('ss', ss).replace('s', second)
                .replace('S', miliseconds)
                .replace('dd', dd).replace('d', day)

                .replace('EEEE', EEEE).replace('EEE', EEE)
                .replace('yyyy', yyyy)
                .replace('yy', yy)
                .replace('aaa', aaa);
                if (patternStr.indexOf('MMM') > -1) {
                    patternStr = patternStr
                    .replace('MMMM', MMMM)
                    .replace('MMM', MMM);
                }
                else {
                    patternStr = patternStr
                    .replace('MM', MM)
                    .replace('M', M);
                }
                return patternStr;
            },
            prettyDate(date) {
                let dateObj = new Date(date.date);
                return this.formatDate(dateObj, 'MMMM d, yyyy');
            },
            dateShort(date) {
                let dateObj = new Date(date.date);
                return this.formatDate(dateObj, 'dd/MM/yyyy');
            },
            prettyDateShort(date) {
                let dateObj = new Date(date.date);
                return this.formatDate(dateObj, 'MMM dd');
            },
        },
        setup(props) {
            let readMoreLink = props.readMoreLink

            if (!readMoreLink) {
                readMoreLink = {};
            }
            if (!readMoreLink.name) {
                readMoreLink.name = "Read More About The " + props.name
            }

            return reactive({readMoreLink})
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .js {
        .h2 {
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 10px;
        }

        @include block('cd-h-timeline') {
            opacity: 0;
            transition: opacity .2s;


            @include modifier('loaded') {
                opacity: 1;
            }

            @include element('container') {
                position: relative;
                height: 100px;
                max-width: 960px;
            }

            @include element('date') {
                position: absolute;
                bottom: 0;
                z-index: 2;
                text-align: center;
                font-size: 1.0em;
                padding-bottom: .75em;
                padding-bottom: 10px;
                color: $white;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                text-decoration: none;
                font-weight: bold;
                top: -35px;

                @include modifier('selected') {
                    pointer-events: none;
                    &::after {
                        background-color: #72c781;
                        border-color: #72c781;
                    }
                }

                @include modifier('older-event') {
                    &::after {
                        border-color: #72c781;
                        background-color: #72c781;
                    }

                    &:hover::after {
                        border-color: #72c781;
                        background-color: #72c781;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
                    bottom: -7px;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    border-width: 2px;
                    border-style: solid;
                    border-color: #86C587;
                    background-color: $pine-green;
                    transition: background-color .3s, border-color .3s;
                }

                &:hover::after {
                    background-color: $blue--med;
                    border-color: $primary;
                }
            }

            @include element('dates') {
                position: relative;
                height: 100%;
                margin: 0 40px;
                overflow: hidden;

                &::after, &::before {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    height: 100%;
                    width: 20px;
                }

                &::before {
                    left: 0;
                    background: linear-gradient(to right, hsl(0, 0%, 97%), hsla(0, 0%, 97%, 0));
                    background: linear-gradient(to right, var(--cd-color-3), hsla(var(--cd-color-3-h), var(--cd-color-3-s), var(--cd-color-3-l), 0));
                }

                &::after {
                    right: 0;
                    background: linear-gradient(to left, hsl(0, 0%, 97%), hsla(0, 0%, 97%, 0));
                    background: linear-gradient(to left, var(--cd-color-3), hsla(var(--cd-color-3-h), var(--cd-color-3-s), var(--cd-color-3-l), 0));
                }
            }

            @include element('line') {
                position: absolute;
                z-index: 1;
                left: 0;
                top: 49px;
                height: 6px;
                background-color: #dcdde3;
                transition: -webkit-transform .4s;
                transition: transform .4s;
                transition: transform .4s, -webkit-transform .4s;
            }

            @include element('filling-line') {
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: #72c781;
                -webkit-transform: scaleX(0);
                -ms-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: left center;
                -ms-transform-origin: left center;
                transform-origin: left center;
                transition: -webkit-transform .3s;
                transition: transform .3s;
                transition: transform .3s, -webkit-transform .3s;
            }

            @include element('navigation') {
                position: absolute;
                z-index: 1;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                height: 34px;
                width: 34px;
                border-radius: 50%;
                border-width: 2px;
                border-style: solid;
                border-color: $white;
                transition: border-color .3s;

                &::after {
                    content: '';
                    position: absolute;
                    height: 16px;
                    width: 16px;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translateX(-50%) translateY(-50%);
                    -ms-transform: translateX(-50%) translateY(-50%);
                    transform: translateX(-50%) translateY(-50%);
                    background: url(../../assets/svg/cd-arrow.svg) no-repeat 0 0;
                }

                &:hover, &:active, &:focus {
                    border-color: #72c781;
                }

                @include modifier('prev') {
                    left: 0;
                    -webkit-transform: translateY(-50%) rotate(180deg);
                    -ms-transform: translateY(-50%) rotate(180deg);
                    transform: translateY(-50%) rotate(180deg);
                }

                @include modifier('next') {
                    right: 0;
                }

                @include modifier('inactive') {
                    cursor: not-allowed;
                    &::after {
                        background-position: 0 -16px;
                    }

                    &:hover, &:active, &:focus {
                        border-color: #72c781;
                    }
                }
            }

            @include element('event') {
                position: absolute;
                z-index: 1;
                width: 100%;
                left: 0;
                top: 0;
                -webkit-transform: translateX(-100%);
                -ms-transform: translateX(-100%);
                transform: translateX(-100%);
                opacity: 0;
                -webkit-animation-duration: .4s;
                animation-duration: .4s;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
                max-width: 940px;
                margin: 0 auto;
                @include modifier('selected') {
                    position: relative;
                    z-index: 2;
                    opacity: 1;
                    -webkit-transform: translateX(0);
                    -ms-transform: translateX(0);
                    transform: translateX(0);
                }

                @include modifier('enter-right') {
                    -webkit-animation-name: cd-enter-right;
                    animation-name: cd-enter-right;
                }

                @include modifier('leave-right') {
                    -webkit-animation-name: cd-enter-right;
                    animation-name: cd-enter-right;
                }

                @include modifier('enter-left') {
                    -webkit-animation-name: cd-enter-left;
                    animation-name: cd-enter-left;
                }

                @include modifier('leave-left') {
                    -webkit-animation-name: cd-enter-left;
                    animation-name: cd-enter-left;
                }

                @include modifier('leave-right') {
                    animation-direction: reverse;
                }

                @include modifier('leave-left') {
                    animation-direction: reverse;
                }
            }

            @include element('events') {
                position: relative;
                width: 100%;
                overflow: hidden;
                transition: height .4s;
                ol {
                    list-style-type: none;
                }
            }

            @include element('event-content') {
                max-width: 1000px;
                text-align: left;
            }

            @include element('event-title') {
                color: $white;
                font-family: playfair display, serif;
                font-family: var(--font-secondary);
                font-weight: 700;
                font-size: 24px;
            }

            @include element('event-date') {
                display: block;
                font-style: italic;
                margin: .5em auto;
                margin: var(--space-xs) auto;
                margin-bottom: 5px;
            }

        }

    }

    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .text-replace {
        overflow: hidden;
        color: transparent;
        text-indent: 100%;
        white-space: nowrap
    }

    .text-center {
        text-align: center
    }

    .text-left {
        text-align: left
    }

    .text-right {
        text-align: right
    }

    @media(min-width:32rem) {
        .text-center\@xs {
            text-align: center
        }

        .text-left\@xs {
            text-align: left
        }

        .text-right\@xs {
            text-align: right
        }
    }

    @media(min-width:48rem) {
        .text-center\@sm {
            text-align: center
        }

        .text-left\@sm {
            text-align: left
        }

        .text-right\@sm {
            text-align: right
        }
    }

    @media(min-width:64rem) {
        .text-center\@md {
            text-align: center
        }

        .text-left\@md {
            text-align: left
        }

        .text-right\@md {
            text-align: right
        }
    }

    @media(min-width:80rem) {
        .text-center\@lg {
            text-align: center
        }

        .text-left\@lg {
            text-align: left
        }

        .text-right\@lg {
            text-align: right
        }
    }

    @media(min-width:90rem) {
        .text-center\@xl {
            text-align: center
        }

        .text-left\@xl {
            text-align: left
        }

        .text-right\@xl {
            text-align: right
        }
    }

    @-webkit-keyframes cd-enter-right {
        0% {
            opacity: 0;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @keyframes cd-enter-right {
        0% {
            opacity: 0;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @-webkit-keyframes cd-enter-left {
        0% {
            opacity: 0;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @keyframes cd-enter-left {
        0% {
            opacity: 0;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }
</style>

