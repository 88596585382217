<template>

    <general-container :id="'top-agencies'">

        <template
            v-if="agencies && agencies.length"
            v-for="agency in agencies"
            :key="agency.contentId"
        >

            <div class="info-block">

                <div class="columns">
                    <div class="column">
                        <p class="heading mb-5">Agency</p>
                        <tag-agency :tagText="agency.name" :tagUrl="agency.url"  />
                    </div>
                </div>

                <p class="operating-budget-title has-text-centered">
                    <Tooltip :tooltipText="definitions && definitions['Operating Budget'] && definitions['Operating Budget'].definition ? definitions['Operating Budget'].definition : 'Loading...'">
                        <span class='has-tooltip'>
                            Operating Budget
                        </span>
                    </Tooltip> - ${{ priceFormat(agency.budgetAppropriations) }}</p>

                <div class="barWrapper">
                    <div class="miniBar">
                        <div :class="'miniBarProgress spent '" :style="'left: 0; width: ' + getPC(agency) + '%; background-color: gray;'" v-if="getPC(agency) > 0"><span>{{ getPC(agency) }}%</span></div>
                        <div :class="'miniBarProgress remains ' + checkAmount()" :style="'left: ' + getPC(agency) + '%; width: ' + (100 - getPC(agency)) + '%; background-color: red;'"><span>{{ 100 - getPC(agency) + '%' }}</span></div>
                    </div>
                </div>

                <nav class="level progress-level">
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="heading">Amount Spent</p>
                            <p class="title">${{ priceFormat(agency.budgetDisbursements) + ' (' + getPC(agency) + '%)' }}</p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <span class="slash">/</span>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="heading">Budget Remaining</p>
                            <p class="title">${{ priceFormat(agency.budgetAppropriations - agency.budgetDisbursements) + ' (' + (100 - getPC(agency)) + '%)' }}</p>
                        </div>
                    </div>
                </nav>
            </div>
        </template>

        <preloader v-if="loading"></preloader>
        <p v-if="!loading && agencies.length == 0" id="no-entries">Check back later.</p>

        <read-more-link contents="View All Agency Budgets" url="/browse-budgets"></read-more-link>

    </general-container>

</template>

<script>
    import { ref, computed } from "vue";
    import { useStore } from "vuex";
    import GeneralContainer from "./general-container"
    import InfoBlock from "./info-block"
    import InfoBlockStacked from "./info-block--stacked"
    import ReadMoreLink from "./read-more-link"
    import RequestHandler from "@/handler/RequestHandler"
    import Preloader from "./preloader";
    import { priceFormat } from "@/helpers/filter";
    import TagAgency from "./tag--agency"
    import Tooltip from "./tooltip";

    export default {
        name: 'TopAgenciesBlock',
        props: [],
        components: {
            InfoBlock,
            InfoBlockStacked,
            GeneralContainer,
            ReadMoreLink,
            Preloader,
            TagAgency,
            Tooltip
        },
        mounted() {
            this.loading = true;
            RequestHandler.loadTopAgencies().then(ContentList => {
                this.agencies = ContentList.list;
                this.loading = false;
            }).catch((error) => {
                console.error(error);
            });

            window.addEventListener('scroll', this.handleScroll);
        },
        data() {
            return {
                agencies: [],
                loading: true,
            }
        },
        methods: {
            getPC(agency) {
                if ((agency.budgetDisbursements / agency.budgetAppropriations) > 1) {
                    return 100;
                } else {
                    return Math.round(agency.budgetDisbursements / agency.budgetAppropriations * 100);
                }
            },
            checkAmount(agency) {
                if (!agency) {
                    return '';
                }
                let amount = Math.round(agency.budgetDisbursements / agency.budgetAppropriations * 100);
                if (amount == 100) {
                    return ' full';
                } else if (amount == 0) {
                    return ' empty';
                }
            }
        },
        setup(props) {
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            return {
                priceFormat,
                definitions
            };
        }
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";

    .slash {
        font-size: 32px;
        font-weight: normal;
        padding: 0 10px;
    }

    .level-item .title {
        font-size: 1rem;
    }
    .heading {
        color: $grey--dark;
        font-weight: 400;
        margin-bottom: 0!important;
        font-size: 11px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        text-transform: uppercase !important;
        font-family: $header--alt;
    }
    .operating-budget-title {
        font-weight: bold;
        font-size: 16px;
        margin-top: 15px;
        margin-bottom: 5px;
    }
    .mb-5 {
        margin-bottom: 5px !important;
    }
</style>
